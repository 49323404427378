.bg {
  background: url('../../public/images/bg5.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  /* background-attachment: fixed; */
  height: 400px;
}

/* linear-gradient(255, 255, 255, 0.7) */
